import React from 'react'
import { makeStyles, Container, Typography, Button } from '@material-ui/core'
import Img from 'gatsby-image'
export default function BlogList({image,title="",synopsis="",link=""}){
const useStyles=makeStyles(theme=>({
container:{
    display:"grid",
    gridTemplateColumns:"1fr 1fr",
    justifyItems:"start",
    alignItems:"top",
    gridGap:"1rem",
    gridTemplateAreas:`"image title"
                        "image text"
                        `,
    [theme.breakpoints.down('sm')]:{
        gridTemplateAreas:`"title title"
                        "image image"
                        "text text"
                        `,
        alignItems:"start",
        justifyItems:"center"
    }
},
image:{
    gridArea:"image",
    width:"100%",
    maxWidth:"300px",
    justifySelf:"center",
    [theme.breakpoints.down('sm')]:{
        maxWidth:"100%"
    }
},
title:{
    gridArea:"title"
},
text:{
    gridArea:"text",
    display:"flex",
    flexDirection:"column"
}
}))
const classes=useStyles()
    return(
        <Container maxWidth="lg">
            <div className={classes.container}>
                <div className={classes.image}>
                <Img fluid={image}/>
                </div>
                <div className={classes.text}>
                <Typography variant="body1" color="primary">{synopsis}</Typography>
                <Button href={link} style={{marginTop:"2rem"}} variant="contained" color="primary">Read post</Button>
                </div>
                <div className={classes.title}>
                <Typography variant="h4" color="primary">{title}</Typography>
                </div>
            </div>
        </Container>
    )
}
import React from "react"
import { Typography, Link, Button, makeStyles } from "@material-ui/core"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import BlogList from "../components/ui/blog/bloglist"
import slugify from "@sindresorhus/slugify"
export default function Blog({ data }) {
  const useStyles = makeStyles(theme => ({
    margins: {
      margin: theme.spacing(12, 0),
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(6, 0),
      },
    },
  }))
  const classes = useStyles()
  return (
    <Layout
      showNav={true}
      menuData={data.allSanitySiteConfig.edges[0].node}
      disableScroll={true}
    >
      <Typography variant="h1" color="primary" align="center">
        Growing your personal brand
      </Typography>
      <div className={classes.margins}>
        {data.allSanityPage.edges.map(({ node }, index) => (
          <Button
            tabIndex={0}
            style={{ textTransform: "capitalize" }}
            variant="text"
            fullWidth
            href={`/blog/${slugify(node.title.toLowerCase())}`}
            key={index}
          >
            <BlogList
              title={node.title}
              image={node.openGraphImage.asset.fluid}
              synopsis={node.description}
            />
          </Button>
        ))}
      </div>
    </Layout>
  )
}
export const query = graphql`
  {
    allSanityPage(filter: { blogPost: { eq: true } }) {
      edges {
        node {
          blogPost
          title
          id
          description
          openGraphImage {
            asset {
              fluid(maxWidth: 500) {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    allSanitySiteConfig {
      edges {
        node {
          id
          contactemail
          contactphone
          title
          mainNavigation {
            slug {
              current
            }
            page {
              ... on SanityPage {
                id
                title
              }
            }
          }
          logo {
            asset {
              url
            }
          }
        }
      }
    }
  }
`
